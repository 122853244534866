//
//
//
//
//
//
import Pdfh5 from 'pdfh5';
import "pdfh5/css/pdfh5.css";
export default {
  name: 'Preview',
  data: function data() {
    return {
      pdfh5: null
    };
  },
  mounted: function mounted() {
    this.pdfh5 = new Pdfh5("#preview-pdf-content", {
      pdfurl: this.$route.query.url
    });
    this.pdfh5.on("complete", function (status, msg, time) {
      console.log("状态：" + status + "，信息：" + msg + "，耗时：" + time + "毫秒，总页数：" + this.totalNum);
    });
  }
};