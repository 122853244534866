//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import request from '@/utils/request';
import "./index.scss";
export default {
  name: 'index',
  data: function data() {
    return {
      detail: {}
    };
  },
  created: function created() {
    var item = this.getQuery('item');
    this.getDetail(item);
  },
  mounted: function mounted() {},
  destroyed: function destroyed() {},
  beforeCreate: function beforeCreate() {
    document.querySelector('body').setAttribute('style', 'background-color: white !important;');
  },
  beforeDestroy: function beforeDestroy() {
    document.body.removeAttribute('style');
  },
  methods: {
    getDetail: function getDetail(item) {
      if (item) {
        this.detail = JSON.parse(item);
      }
    }
  }
};