//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import "./index.scss";
import wx from "weixin-js-sdk";
export default {
  name: "Detail",
  mounted: function mounted() {
    window.addEventListener("popstate", this.returnMiniApp, false);
  },
  destroyed: function destroyed() {
    window.removeEventListener("popstate", this.returnMiniApp, false);
  },
  methods: {
    returnMiniApp: function returnMiniApp() {
      // 跳转到上一个小程序页面
      wx.miniProgram.navigateBack({
        delta: 1
      });
    },
    goInformation: function goInformation() {
      this.$router.push({
        path: "/home-self-checking/complete-info",
        query: {
          clientId: this.$route.query.clientId,
          projectId: this.$route.query.projectId,
          ownershipId: this.$route.query.ownershipId,
          ownershipGroupId: this.$route.query.ownershipGroupId,
          orderType: this.$route.query.orderType
        }
      });
    }
  }
};