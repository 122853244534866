//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import request from "@/utils/request";
import "./index.scss";
export default {
  name: "StepDetail",
  props: {
    detail: {
      type: Object,
      default: {}
    }
  },
  data: function data() {
    return {
      step: 1,
      loading: false
    };
  },
  watch: {
    detail: function detail() {
      if (this.detail.status === "5") {
        this.step = 2;
      } else if (this.detail.status === "2") {
        this.step = 3;
      } else if (this.detail.status === "3") {
        this.step = 4;
      }
    }
  },
  methods: {
    goDetail: function goDetail() {// this.$router.push({
      //     path: `/nursing/service-detail?title=${this.detail.name}&from=order`
      //   })
    }
  }
};